
// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    0;


// Variables

//
// Color system
//

$white:  #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:  #000 !default;

$grays: (
  100: $gray-100,
  200: $gray-200,
  300: $gray-300,
  400: $gray-400,
  500: $gray-500,
  600: $gray-600,
  700: $gray-700,
  800: $gray-800,
  900: $gray-900
) !default;

$blue:    #0099CE !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800
) !default;

$theme-colors: (
  primary: $blue,
  secondary: $gray-600,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800
) !default;

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded:            true !default;
$enable-shadows:            false !default;
$enable-gradients:          false !default;
$enable-transitions:        false !default;
$enable-hover-media-query:  false !default;
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3)
) !default;

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%
) !default;

// Body
//
// Settings for the `<body>` element.

$body-bg:       $white !default;
$body-color:    #58595b !default;

// Links
//
// Style anchor elements.

$link-color:            theme-color("primary") !default;
$link-decoration:       none !default;
$link-hover-color:      darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;
@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:         1.5 !default;
$line-height-sm:         1.5 !default;

$border-width: 1px !default;

$border-radius:          .25rem !default;
$border-radius-lg:       .3rem !default;
$border-radius-sm:       .2rem !default;

$component-active-color: $white !default;
$component-active-bg:    theme-color("primary") !default;

$caret-width:            .3em !default;

$transition-base:        all .2s ease-in-out !default;
$transition-fade:        opacity .15s linear !default;
$transition-collapse:    height .35s ease !default;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: "PT Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:       $font-family-sans-serif !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:   1.25rem !default;
$font-size-sm:   .875rem !default;

$font-weight-normal: normal !default;
$font-weight-bold: bold !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

$h1-font-size: 2.5rem !default;
$h2-font-size: 2rem !default;
$h3-font-size: 1.75rem !default;
$h4-font-size: 1.5rem !default;
$h5-font-size: 1.25rem !default;
$h6-font-size: 1rem !default;

$headings-margin-bottom: ($spacer / 2) !default;
$headings-font-family:   "PT Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$headings-font-weight:   500 !default;
$headings-line-height:   1.1 !default;
$headings-color:         #58595b !default;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;

$display1-weight:     300 !default;
$display2-weight:     300 !default;
$display3-weight:     300 !default;
$display4-weight:     300 !default;
$display-line-height: $headings-line-height !default;

$lead-font-size:   1.25rem !default;
$lead-font-weight: 300 !default;

$small-font-size: 80% !default;

$text-muted: $gray-600 !default;

$blockquote-small-color:  $gray-600 !default;
$blockquote-font-size:    ($font-size-base * 1.25) !default;

$hr-border-color: rgba($black,.1) !default;
$hr-border-width: $border-width !default;

$mark-padding: .2em !default;

$dt-font-weight: $font-weight-bold !default;

$kbd-box-shadow:         inset 0 -.1rem 0 rgba($black,.25) !default;
$nested-kbd-font-weight: $font-weight-bold !default;

$list-inline-padding: 5px !default;

$mark-bg: #fcf8e3 !default;


// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

$input-btn-padding-y:       .5rem !default;
$input-btn-padding-x:       .75rem !default;
$input-btn-line-height:     1.25 !default;

$input-btn-padding-y-sm:    .25rem !default;
$input-btn-padding-x-sm:    .5rem !default;
$input-btn-line-height-sm:  1.5 !default;

$input-btn-padding-y-lg:    .5rem !default;
$input-btn-padding-x-lg:    1rem !default;
$input-btn-line-height-lg:  1.5 !default;

$btn-font-weight:                $font-weight-normal !default;
$btn-box-shadow:                 inset 0 1px 0 rgba($white,.15), 0 1px 1px rgba($black,.075) !default;
$btn-focus-box-shadow:           0 0 0 3px rgba(theme-color("primary"), .25) !default;
$btn-active-box-shadow:          inset 0 3px 5px rgba($black,.125) !default;

$btn-link-disabled-color:        $gray-600 !default;

$btn-block-spacing-y:            .5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:              $border-radius !default;
$btn-border-radius-lg:           $border-radius-lg !default;
$btn-border-radius-sm:           $border-radius-sm !default;

$btn-transition:                 all .15s ease-in-out !default;


// Forms

$input-bg:                       $white !default;
$input-disabled-bg:              $gray-200 !default;

$input-color:                    $gray-700 !default;
$input-border-color:             rgba($black,.15) !default;
$input-btn-border-width:         $border-width !default; // For form controls and buttons
$input-box-shadow:               inset 0 1px 1px rgba($black,.075) !default;

$input-border-radius:            $border-radius !default;
$input-border-radius-lg:         $border-radius-lg !default;
$input-border-radius-sm:         $border-radius-sm !default;

$input-focus-bg:                 $input-bg !default;
$input-focus-border-color:       lighten(theme-color("primary"), 25%) !default;
$input-focus-box-shadow:         $input-box-shadow, $btn-focus-box-shadow !default;
$input-focus-color:              $input-color !default;

$input-placeholder-color:        $gray-600 !default;

$input-height-border:           $input-btn-border-width * 2 !default;

$input-height-inner:            ($font-size-base * $input-btn-line-height) + ($input-btn-padding-y * 2) !default;
$input-height:                  calc(#{$input-height-inner} + #{$input-height-border}) !default;

$input-height-inner-sm:         ($font-size-sm * $input-btn-line-height-sm) + ($input-btn-padding-y-sm * 2) !default;
$input-height-sm:               calc(#{$input-height-inner-sm} + #{$input-height-border}) !default;

$input-height-inner-lg:         ($font-size-sm * $input-btn-line-height-lg) + ($input-btn-padding-y-lg * 2) !default;
$input-height-lg:               calc(#{$input-height-inner-lg} + #{$input-height-border}) !default;

$input-transition:               border-color ease-in-out .15s, box-shadow ease-in-out .15s !default;

$form-text-margin-top:     .25rem !default;

$form-check-margin-bottom:  .5rem !default;
$form-check-input-gutter:   1.25rem !default;
$form-check-input-margin-y: .25rem !default;
$form-check-input-margin-x: .25rem !default;

$form-check-inline-margin-x: .75rem !default;

$form-group-margin-bottom:       1rem !default;

$input-group-addon-bg:           $gray-200 !default;
$input-group-addon-border-color: $input-border-color !default;


// Form validation
$form-feedback-valid-color:   theme-color("success") !default;
$form-feedback-invalid-color: theme-color("danger") !default;


// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown:           1000 !default;
$zindex-sticky:             1020 !default;
$zindex-fixed:              1030 !default;
$zindex-modal-backdrop:     1040 !default;
$zindex-modal:              1050 !default;
$zindex-popover:            1060 !default;
$zindex-tooltip:            1070 !default;

// Navs

$nav-link-padding-y:            .5rem !default;
$nav-link-padding-x:            1rem !default;
$nav-link-disabled-color:       $gray-600 !default;

$nav-tabs-border-color:                       #ddd !default;
$nav-tabs-border-width:                       $border-width !default;
$nav-tabs-border-radius:                      $border-radius !default;
$nav-tabs-link-hover-border-color:            $gray-200 !default;
$nav-tabs-link-active-color:                  $gray-700 !default;
$nav-tabs-link-active-bg:                     $body-bg !default;
$nav-tabs-link-active-border-color:           #ddd !default;

$nav-pills-border-radius:     $border-radius !default;
$nav-pills-link-active-color: $component-active-color !default;
$nav-pills-link-active-bg:    $component-active-bg !default;

// Navbar

$navbar-padding-y:                  ($spacer / 2) !default;
$navbar-padding-x:                  $spacer !default;

$navbar-brand-font-size:            $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height:                   $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-height:               ($font-size-base * $line-height-base + $nav-link-padding-y * 2) !default;
$navbar-brand-padding-y:            ($navbar-brand-height - $nav-link-height) / 2 !default;

$navbar-toggler-padding-y:           .25rem !default;
$navbar-toggler-padding-x:           .75rem !default;
$navbar-toggler-font-size:           $font-size-lg !default;
$navbar-toggler-border-radius:       $btn-border-radius !default;

$navbar-dark-color:                 rgba($white,.5) !default;
$navbar-dark-hover-color:           rgba($white,.75) !default;
$navbar-dark-active-color:          rgba($white,1) !default;
$navbar-dark-disabled-color:        rgba($white,.25) !default;
$navbar-dark-toggler-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-dark-toggler-border-color:  rgba($white,.1) !default;

$navbar-light-color:                rgba($black,.5) !default;
$navbar-light-hover-color:          rgba($black,.7) !default;
$navbar-light-active-color:         rgba($black,.9) !default;
$navbar-light-disabled-color:       rgba($black,.3) !default;
$navbar-light-toggler-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-light-toggler-border-color: rgba($black,.1) !default;


// Breadcrumbs

$breadcrumb-padding-y:          .75rem !default;
$breadcrumb-padding-x:          1rem !default;
$breadcrumb-item-padding:       .5rem !default;

$breadcrumb-bg:                 $gray-200 !default;
$breadcrumb-divider-color:      $gray-600 !default;
$breadcrumb-active-color:       $gray-600 !default;
$breadcrumb-divider:            "/" !default;

// Block
$block-color:              inherit;
$block-bg:                 #fff;
$block-color-inverted:     #58595b;
$block-bg-inverted:        #F2FAFA;
$block-title-font-size-sm: $font-size-base * 4;
$block-title-font-size:    $font-size-base * 3;
$block-lead-font-size-sm:  $font-size-base * 1.5;
$block-lead-font-size:     $font-size-base * 1.3;
$block-vertical-padding:   60px;
