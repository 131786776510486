header {
 border-bottom:1px solid #e7e7e7;

.menu-item {
  @extend .nav-item;

  a {
    @extend .nav-link;
    color:#0099CE;
  }
}

.navbar-nav .active a{
  @extend .btn;
  @extend .btn-outline-primary;
    color:#0099CE;
    border-color: #0099CE;
}

.lang-item {
  @extend .mr-3;
}

}
