footer {

  
  a {

    font-size: 0.875em;
    color: rgba(0,0,0,0.88);

    &:hover, &:focus {
      color:rgba(0,0,0,1);
      background:none;
    }
  }


}

.bottom-navigation {
  background:#000;

  p {

    font-size: 0.775em;
    color: rgba(255,255,255,0.68);
  }



  a {

    font-size: 0.775em;
    color: rgba(255,255,255,0.68);

    &:hover, &:focus {
      color:$white;
      background:none;
    }
  }

  .list-copyright {
    padding-top:5px;
  }

}
