// Titles

body,
p,
p.lead,
h1,
h2,
h3,
h4,
h5,h6 {
  font-family: "PT Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
     color: #58595b;
}

h2,
.display-4 {
   font-weight: 700;
   color: #58595b;
}

p {
  font-size: 1.2em;
}
.block-inverse {

 h4,
 .subtitle {

    color: #0099ce;

 }
  p {
  color:#fff;
  }
}

@include media-breakpoint-down(sm) {
  h2.display-4 {
    font-size: 2.5em;
  }
}

.subtitle {
  color:#0099CE;
}

.paragraph-inverse {
  background:#30ACD7;
  color:#fff;
}


/* transforms */
.app-translate-15 {
  -webkit-transform: translateY(15px);
      -ms-transform: translateY(15px);
          transform: translateY(15px);
}
.app-translate-50 {
  -webkit-transform: translateY(50px);
      -ms-transform: translateY(50px);
          transform: translateY(50px);
}
.app-translate-5 {
  -webkit-transform: translateY(5%);
      -ms-transform: translateY(5%);
          transform: translateY(5%);
}
.app-translate-10 {
  -webkit-transform: translateY(10%);
      -ms-transform: translateY(10%);
          transform: translateY(10%);
}
